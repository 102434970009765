<template>
  <a-modal
    title="积分使用详情"
    :width="800"
    :visible="visible"
    :destroyOnClose="true"
    @cancel="handleCancel"
    :footer="null"
  >
    <a-spin :spinning="confirmLoading">
      <a-row style="overflow: auto">
        <a-form layout="inline">
          <a-col :md="12" :sm="24" v-if="details.orderNo">
            <a-form-item label="订单编号">
              <span>{{ details.orderNo }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="用户昵称">
              <span>{{ details.nickname }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="手机号">
              <span>{{ details.mobile }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="类型">
              <span>{{ details.recordTypeName }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="使用积分">
              <span>{{ details.price }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item label="使用时间">
              <span>{{ details.createTime }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24" v-if="details.goodsPageResults && Array.isArray(details.goodsPageResults)">
            <a-form-item label="商品信息">
              <a-table :columns="columns" :data-source="details.goodsPageResults" :pagination="false" rowKey="id">
              </a-table>
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { integralRecordDetail } from '@/api/modular/mallLiving/recharge'
const columns = [
  {
    title: '序号',
    width: '100px',
    align: 'center',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '商品名称',
    width: '100px',
    dataIndex: 'goodsName',
    align: 'center',
  },

  {
    title: '数量',
    width: '100px',
    dataIndex: 'buyNum',
    align: 'center',
  },
  {
    title: '规格',
    width: '100px',
    dataIndex: 'specName',
    align: 'center',
  },
  {
    title: '商品单价',
    width: '100px',
    dataIndex: 'originPrice',
    align: 'center',
  },
  {
    title: '商品总价',
    width: '100px',
    dataIndex: 'totalPrice',
    align: 'center',
  },
  {
    title: '供应商',
    width: '100px',
    dataIndex: 'storeName',
    align: 'center',
  },
  {
    title: '供应商联系人',
    width: '100px',
    dataIndex: 'linkMobile',
    align: 'center',
  },
]
export default {
  data() {
    return {
      columns,
      visible: false, //modal框显示状态
      confirmLoading: false,
      details: {},
      form: this.$form.createForm(this),
      taskDetail: {
        agentBusinessPrice: 0,
        businessPrice: 0,
        agentNewNumber: 0,
        newNumber: 0,
      },
    }
  },
  methods: {
    //初始化方法
    detail(record) {
      this.visible = true
      this.confirmLoading = true
      //获取详情数据
      setTimeout(() => {
        integralRecordDetail({
          id: record.id,
        })
          .then((res) => {
            if (res.success) {
              this.details = res.data
              this.confirmLoading = false
            }
          })
          .finally((res) => {
            setTimeout(() => {
              this.confirmLoading = false
            }, 5000)
          })
      }, 100)
    },
    // 点击遮罩层或右上角叉或取消按钮的操作
    handleCancel() {
      this.visible = false
      this.details = {} //关闭之后清空
    },
  },
}
</script>
<style lang="less" scoped>
.mytable {
  margin-bottom: 70px;
  border-collapse: collapse;
  width: 100%;
  height: 250px;

  .title {
    background: rgb(207, 248, 248);
    width: 20%;
  }

  td {
    border: 2px solid rgb(228, 225, 225);
    padding: 7px;
    font-size: 15px;
    width: 30%;
  }
}

img {
  height: 100px;
}
</style>
